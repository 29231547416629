import React from "react";

const Footnote = ({ footnote }) => {
  return (
    <div className="row">
      <h5 className="text-danger text-center">
        <small>{footnote}</small>
      </h5>
    </div>
  );
};

export default Footnote;
