import React, { useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const Stats = ({ data }) => {
  const { api } = useApi();
  const { rIndex, cell, index, threshold } = data;

  const [packetLoss, setPacketLoss] = useState("--");

  const handleCellMouseEnter = async (sensorId) => {
    if (sensorId === undefined) {
    } else {
      const response = await api.list(`/sensors/${sensorId}/channels`);

      const channels = response.data;
      if (channels) {
        const channel = channels.find(
          (element) => element.name === "Packet Loss"
        );

        setPacketLoss(channel.lastvalue);
      }
    }
  };

  useEffect(() => {});

  const popover = (
    <Popover
      id={rIndex + "" + index}
      size={"md"}
      style={{ backgroundColor: "black" }}
    >
      <Popover.Header
        as="h3"
        className="text-center"
        style={{ backgroundColor: "black", color: "white" }}
      >
        {cell?.name || cell?.notfound}
      </Popover.Header>
      <Popover.Body>
        <span className="d-flex flex-column text-start mb-2 text-white">
          <span>Latency: {index > 0 && cell?.lastvalue}</span>
          <span>Packet Loss: {index > 0 && packetLoss}</span>
        </span>
      </Popover.Body>
    </Popover>
  );

  const cellValue = index > 0 ? Math.round(cell?.lastvalue_raw) : cell;

  //get the variation between threshold and cellvalue
  const cellValueLessThreshold = cellValue - threshold.value;

  return index === 0 ? (
    <td className="row-heading">{(cellValue && cellValue) || ""}</td>
  ) : rIndex + 1 === index ? (
    <td className={"row-data latency-ok"}>{""}</td>
  ) : (
    <OverlayTrigger key={rIndex + "" + index} placement="top" overlay={popover}>
      <td
        onMouseEnter={() => {
          handleCellMouseEnter(cell.objid);
        }}
        className={
          index === 0
            ? "row-heading latency-ok"
            : !cellValue
            ? "row-data bg-secondary"
            : cellValueLessThreshold <= 9
            ? "row-data latency-normal"
            : cellValueLessThreshold <= 20
            ? "row-data latency-high"
            : "row-data latency-above"
        }
      >
        {(cellValue && cellValue) || threshold.value}
      </td>
    </OverlayTrigger>
  );
};

export default Stats;
