import React from "react";
import { useQuery } from "react-query";
// import Table from "react-bootstrap/Table";
import useApi from "../hooks/useApi";
import Stats from "./Stats";
import "../App.css";

const NetworkStats = ({ thresholds, cities }) => {
  const { api } = useApi();

  const getSensors = async () => {
    return await api.list();
  };

  const { data } = useQuery("sensors", ({ queryKey }) => getSensors(), {
    refetchInterval: 35000,
  });

  const popRow = [];

  const sensorStats = data?.data || [];

  cities.forEach((header) => {
    const colRow = [];
    colRow.push(header);
    cities.forEach((col) => {
      const rowHeader = `${header}-${col}`;
      const rowHeaderAlt = `${col}-${header}`;
      const row = sensorStats.find(
        (sensor) => sensor.name === rowHeader && sensor.lastvalue
      );
      const rowAlt = sensorStats.find(
        (sensor) => sensor.name === rowHeaderAlt && sensor.lastvalue
      );

      if (row) {
        colRow.push(row);
      } else {
        if (rowAlt) {
          colRow.push(rowAlt);
        } else {
          if (header !== col) {
            colRow.push({ notfound: rowHeader });
          } else {
            colRow.push({});
          }
        }
      }
    });
    popRow.push(colRow);
  });
  return (
    <div className="table-responsive">
      <table className="table" style={{ width: 700 }}>
        <thead>
          <tr>
            <th className="text-upright"></th>
            {cities.map((header) => (
              <th key={header} className="text-upright">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {popRow.map((row, rIndex) => (
            <tr key={rIndex}>
              {row.map((cell, index) => (
                <Stats
                  key={rIndex + "" + index}
                  data={{
                    row,
                    rIndex,
                    cell,
                    index,
                    threshold:
                      thresholds?.find(
                        (element) => element.link === cell?.name
                      ) ||
                      thresholds?.find(
                        (element) => element.link === cell?.notfound
                      ) ||
                      0,
                  }}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NetworkStats;
