// import * as React from "react";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import NetworkStats from "./components/NetworkStats";
// import Footnote from "./components/Footnote";
import "bootstrap/dist/css/bootstrap.min.css";
import useApi from "../src/hooks/useApi";
import Layout from "./components/Layout";

export default function App() {
  const queryClient = new QueryClient();

  const [footnote, setFootnote] = useState();
  const [cities, setCities] = useState([]);
  const [thresholds, setThresholds] = useState([]);

  const { api } = useApi();

  useEffect(() => {
    const getSettings = async () => {
      try {
        const { data } = await api.list("/stats/settings");

        setFootnote(data.footnote);
        setCities(data.cities);
        setThresholds(data.thresholds);
      } catch (error) {}
    };

    getSettings();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route element={<Layout footnote={footnote} />}>
          <Route
            path=""
            element={<NetworkStats thresholds={thresholds} cities={cities} />}
          />
        </Route>

        <Route path="/geofeed.csv" element={<h2>geofeed</h2>} />
      </Routes>

      {/* <Footnote footnote={footnote} /> */}
    </QueryClientProvider>
  );
}
