import axios from "axios";

const useApi = () => {
  const remote = "https://webservice.vts.bf/api";
  let url = `http://localhost:9000/api`;

  url = remote;
  const connection = axios.create({
    baseURL: url,
  });

  const api = {
    list: async (endpoint = "/stats") => {
      return connection.get(endpoint);
    },
  };
  return { api };
};

export default useApi;
