import React from "react";

const Header = () => {
  return (
    <header className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4">
      <a
        href="/"
        className="d-flex align-items-center text-dark text-decoration-none"
      >
        <img src="/logo.jpg" width="150" alt="logo"></img>
        <span className="fs-4 pt-2">Network Stats</span>
      </a>
    </header>
  );
};

export default Header;
